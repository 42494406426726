import * as echarts from 'echarts/core';
import {
  TitleComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
  TooltipComponent,
  ToolboxComponent,
  VisualMapComponent,
  GeoComponent,
} from 'echarts/components';
import {
  RadarChart,
  PieChart,
  BarChart,
  MapChart,
  LineChart,
  GaugeChart,
} from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { LabelLayout } from 'echarts/features';
echarts.use([
  TitleComponent,
  LegendComponent,
  TooltipComponent,
  RadarChart,
  CanvasRenderer,
  GridComponent,
  DataZoomComponent,
  BarChart,
  CanvasRenderer,
  PieChart,
  LabelLayout,
  ToolboxComponent,
  VisualMapComponent,
  GeoComponent,
  MapChart,
  LineChart,
  GaugeChart,
]);

export default echarts;
