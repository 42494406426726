import req from '../utils/request';

// 用户登录
export function userLogin(parmaObj) {
  return req.post('/scheduling/user/login1', parmaObj);
}
// 根据权限获取左侧菜单
export function getMenuList(parmaObj) {
  return req.get('/scheduling/user/getMenuList', parmaObj);
}
// 短信验证码获取token、用户信息
export function smsLogin(parmaObj) {
  return req.post('/scheduling/user/smsLogin', parmaObj);
}
// 获取公钥
export function getPublicKey() {
  return req.get('/scheduling/user/getPublicKey');
}
// 免验证码登录
export function noCodeLogin(parmaObj) {
  return req.post('/scheduling/user/noCodeLogin1', parmaObj);
}
// 获取应急响应人站点
export function getStandingList() {
  return req.get('/scheduling/firstresponse-standing/getStandingList');
}
// 获取U站数据
export function getUStationList() {
  return req.get('/scheduling/u-station/getUStationList');
}
// 获取aed数据
export function getAedDetailByArea() {
  return req.get('/safe-precaution-ygl/ygl-resources/getAedDetailByArea');
}
// 获取axed数据
export function getAxedDetailByArea() {
  return req.get('/safe-precaution-ygl/ygl-resources/getAxedDetailByArea');
}
// 获取报警电话
export function getPoliceList(search) {
  return req.get('/scheduling/phone-book/policeList?search=' + search);
}
// 获取所有事件类型
export function eventTypeList() {
  return req.get('/scheduling/event-type/evenTypeList');
}
// 更新事件信息
export function updateEvent(parmaObj) {
  return req.post('/scheduling/event/update', parmaObj);
}
// 获取呼救信息
export function getEvents() {
  return req.get('/scheduling/event/getEvents');
}
// 分区获取接受培训，上线的第一响应人
export function getFirstTrainingUserByArea() {
  return req.get(
    '/safe-precaution-ygl/ygl-resources/getFirstTrainingUserByArea'
  );
}
// 获取腾讯云外呼平台token
export function getCallSdkToken() {
  return req.get('/scheduling/user/getCallSdkToken');
}
// 根据离线时间获取用户
export function getActiveUserInterval(parmaObj) {
  return req.get(
    '/scheduling/user/getActiveUserInterval?timeInterval=' + parmaObj
  );
}
// 切换事件获取事件对应志愿者信息
export function getEventUserId(parmaObj) {
  return req.post('/scheduling/user/selectEBU', parmaObj);
}
// 查找范围内的志愿者(圆形)
export function getUserByDist(timeInterval, dist, lat, lng) {
  return req.get(
    `/scheduling/user/getUserByDist?timeInterval=${timeInterval}&dist=${dist}&lat=${lat}&lng=${lng}`
  );
}
// 调用机器人外呼
export function robotCall(parmaObj) {
  return req.post('/scheduling/user/robotCallTencent', parmaObj);
}
// 给选中的范围内志愿者群发短信
export function sendSmsNotice(parmaObj) {
  return req.post('/scheduling/user/sendSmsNotice', parmaObj);
}
// 获取用户名
export function getUserNameById(parmaObj) {
  return req.post('/scheduling/user/getUserNameById', parmaObj);
}
// 推送消息
export function sendByUser(parmaObj) {
  return req.post('/scheduling/websocket/socket/push/' + parmaObj.id, parmaObj);
}
//更新调度员正在视频通话的事件
export function setControllerVideo(parmaObj) {
  return req.post('/scheduling/user/saveVideoEventId', parmaObj);
}
//调度员参与的事件
export function setControllerEvent(parmaObj) {
  return req.post('/scheduling/event-user/saveDispatcher', parmaObj);
}
// 确认派遣
export function dispatch(parmaObj) {
  return req.post('/scheduling/event/dispatch', parmaObj);
}
// 获取同一事件中的志愿者
export function getEventUser(parmaObj) {
  return req.get('/scheduling/user/getVideoUserIds?userId=' + parmaObj.id);
}
// 发短信通知手表在线的志愿者打开小程序
export function sendSmsOpenApp(parmaObj) {
  return req.post('/scheduling/user/sendSmsOpenApp', parmaObj);
}
//增加待确认的派遣记录
export function setDispatchLog(parmaObj) {
  return req.post('/scheduling/socket-info/setDispatchLog', parmaObj);
}
// 获取事件时间线
export function getBYEventId(id) {
  return req.get('/scheduling/event-time-node/getBYEventId?eventId=' + id);
}
// 信息平台增加设备
export function addDevice(parmaObj) {
  return req.post('/scheduling/event-time-node/addDevice', parmaObj);
}
// 信息平台增加聊天记录
export function addInfo(parmaObj) {
  return req.post('/scheduling/event-time-node/addInfo', parmaObj);
}
//根据id获取角色
export function getRoleById(parmaObj) {
  return req.post('/scheduling/user/getRole', parmaObj);
}
// 根据参数获取志愿者状态
export function getUserStatus(parmaObj) {
  return req.post('/scheduling/user/getOnlineStatus', parmaObj);
}
//志愿者当前参与的事件
export function getEventByUserId(parmaObj) {
  return req.get('/scheduling/event-user/getEventByUserId?id=' + parmaObj.id);
}
// 获取专家列表
export function getSpecialist() {
  return req.get('/scheduling/user/getSpecialist');
}
// 短信通知专家
export function inviteExperts(parmaObj) {
  return req.post('/scheduling/oms/inviteExperts', parmaObj);
}

/* ****************信息平台*************** */
// 信息平台地图补充信息
export function getResourcesByArea() {
  return req.get('/safe-precaution-ygl/ygl-resources/getResourcesByArea');
}
// 获取深圳市内救援事件数量（月、日）
export function getCountByYTD() {
  return req.get('/scheduling/event/getCountByYTD');
}
// 获取深圳发生的不同的紧急事件的数量
export function getTypeCount() {
  return req.get('/scheduling/event/getTypeCount');
}
// 应急资源接口
export function getResourceCount() {
  return req.get('/scheduling/device/getCount');
}
// 信息平台应急资源柱状图
export function getAllResources() {
  return req.get('/safe-precaution-ygl/ygl-resources/getAllResources');
}
// 获取信息平台数据统计接口
export function getInfostageData() {
  return req.get('/safe-precaution-ygl/ygl-infostage/getInfostageData');
}
// 实时救援情况
export function getUserEventCount() {
  return req.get('/scheduling/event/getUserEventCount');
}
// 获取AED状态
export function getUserStatusCount() {
  return req.get('/scheduling/user/getStatusCount');
}
// 获取高峰时段接口
export function getPeakHours() {
  return req.get('/scheduling/user/getPeakHours');
}
/* ********************后台******************* */
// 分页获取事件列表
export function getEventsByPage(parmaObj) {
  return req.post('/scheduling/event/getEventsByPage', parmaObj);
}
// 条件查询事件
export function queryEvent(parmaObj) {
  return req.post('/scheduling/event/queryEvent', parmaObj);
}
// 根据id获取事件详情
export function getEventById(parmaObj) {
  return req.get('/scheduling/event/getById/' + parmaObj.id);
}
// 点击左侧菜单 根据点击菜单id获取对应权限列表
export function getMenuBtnList(parmaObj) {
  return req.get('/scheduling/user/getMenuBtnList?id=' + parmaObj.id);
}
// 根据id获取事件图片记录
export function getEventRecord(parmaObj) {
  return req.get('/scheduling/event-record/record?eventId=' + parmaObj.eventId);
}
// 条件查询志愿者
export function queryVolunteer(parmaObj) {
  return req.post('/scheduling/user/queryVolunteer', parmaObj);
}
// 根据id注销用户
export function logoutById(parmaObj) {
  return req.get('/scheduling/user/logout/' + parmaObj.id);
}
// 人脸活体检测
export function faceAuthentication(parmaObj) {
  return req.post('/scheduling/user/faceAuthentication', parmaObj);
}
// 根据id获取用户详情
export function getUserById(parmaObj) {
  return req.get('/scheduling/user/getById/' + parmaObj.id);
}
// 根据id获取用户证件信息
export function getCertificateById(parmaObj) {
  return req.get(
    '/scheduling/volunteer-certificate/getCertificateById/' + parmaObj.id
  );
}
// 根据id编辑用户资料
export function updateUserById(parmaObj) {
  return req.post('/scheduling/user/updateById', parmaObj);
}
// 条件查询用户
export function queryUser(parmaObj) {
  return req.post('/scheduling/user/queryUser', parmaObj);
}
// 审核
export function auditById(parmaObj) {
  return req.post('/scheduling/user/audit', parmaObj);
}
// 获取所有角色列表，用户管理中点击分配角色调用
export function getAllRoles() {
  return req.get('/scheduling/role/list');
}
// 根据用户id获取用户所拥有的角色id
export function getRoleIdByUserId(parmaObj) {
  return req.get('/scheduling/user/getRoleByUserId/' + parmaObj.userId);
}
// 更新用户拥有角色
export function updateUR(parmaObj) {
  return req.post('/scheduling/user/assignRole', parmaObj);
}
// 分页获取权限列表
export function getPermsByPage(parmaObj) {
  return req.post('/scheduling/permission/getPermsByPage', parmaObj);
}
// 分页获取角色列表
export function getRolesByPage(parmaObj) {
  return req.post('/scheduling/role/getRolesByPage', parmaObj);
}
// 获取所有权限列表，角色管理中点击分配权限调用
export function getAllPerms() {
  return req.get('/scheduling/permission/getAllPerms');
}
// 根据角色id获取角色所拥有的权限id
export function getPermIdByRoleId(parmaObj) {
  return req.get('/scheduling/role/getPermByRoleId/' + parmaObj.roleId);
}
// 更新角色拥有权限
export function updateRP(parmaObj) {
  return req.post('/scheduling/role-permission/updateRP', parmaObj);
}
// 条件查询专家
export function queryExpert(parmaObj) {
  return req.post('/scheduling/user/queryExpert', parmaObj);
}
// 条件查询设备
export function queryDevice(parmaObj) {
  return req.post('/scheduling/device/queryDevice', parmaObj);
}
// 设备资料批量导出
export function batchExportDevices(parmaObj) {
  return req.downFile('/scheduling/device/excelByIds', parmaObj);
}
// 设备批量删除
export function batchDeleteDevices(parmaObj) {
  return req.post('/scheduling/device/deletes', parmaObj);
}
// 获取设备类型列表
export function deviceTypeList() {
  return req.get('/scheduling/device-type/typeList');
}
// 根据id获取设备详情
export function getDeviceById(parmaObj) {
  return req.get('/scheduling/device/getById/' + parmaObj.id);
}
// 根据id编辑设备资料
export function updateDeviceById(parmaObj) {
  return req.post('/scheduling/device/updateById', parmaObj);
}
// 新增设备
export function insertDevice(parmaObj) {
  return req.post('/scheduling/device/save', parmaObj);
}
// 生成二维码
export function insertQrCode(parmaObj) {
  return req.post('/scheduling/qr/insert', parmaObj);
}
// 获取二维码数据
export function getQrCodeByPage(page, pageSize) {
  return req.get(
    '/scheduling/qr/getByPage?page=' + page + '&pageSize=' + pageSize
  );
}
export function deleteQrCode(id) {
  return req.post('/scheduling/qr/del?id=' + id);
}
// 更改调度员在线状态
export function updateBusyStatus(parmaObj) {
  return req.post('/scheduling/user/updateBusyStatus', parmaObj);
}
// 获取志愿者车载设备信息
export function getCarInfoById(userId) {
  return req.get(`/scheduling/vehicle-user/getByUserId?userId=${userId}`);
}
