import axios from 'axios';
import { http } from '../settings';
import router from '@/router';
import { ElMessage } from 'element-plus';
// 创建axios实例
const httpService = axios.create({
  baseURL: http,
  timeout: 60000,
});

let flag = true; // 节流阀

// request拦截器
httpService.interceptors.request.use(
  (config) => {
    // 根据条件加入token-安全携带
    if (true) {
      // 需自定义
      // 让每个请求携带token
      config.headers['Authorization'] = window.sessionStorage.getItem('token');
    }
    return config;
  },
  (error) => {
    // 请求错误处理
    Promise.reject(error);
  }
);

// respone拦截器
httpService.interceptors.response.use(
  (response) => {
    // 统一处理状态
    const res = response.data;
    //放行下载
    if (typeof res == 'string') {
      return response;
    }
    if (res.type) {
      return response.data;
    }
    if (
      res.code == 400 ||
      res.code == 401 ||
      res.code == 502 ||
      res.code == 406 ||
      res.code == 503 ||
      res.code == 411 ||
      res.code == 4117 ||
      res.code == 200002
    ) {
      // || res.code == 411
      if (res.code == 411) {
        if (flag) {
          flag = false;
          ElMessage({
            type: 'error',
            duration: 3000,
            message: '登录过期',
          });
          router.push('/login');
          setTimeout(() => {
            flag = true;
          }, 1500);
        }
      }
      return response.data;
    }
    if (res.code != 200) {
      // 需自定义
      // 返回异常
      return Promise.reject({
        code: res.code,
        msg: res.msg,
      });
    } else {
      return response.data;
    }
  },
  // 处理处理
  (error) => {
    if (error && error.response) {
      switch (error.response.code) {
        case 403:
          error.msg = '拒绝访问';
          break;
        case 404:
          error.msg = '请求错误,未找到该资源';
          break;
        case 405:
          error.msg = '请求方法未允许';
          break;
        case 408:
          error.msg = '请求超时';
          break;
        case 500:
          error.msg = '服务器端出错';
          break;
        case 501:
          error.msg = '网络未实现';
          break;
        case 502:
          error.msg = '网络错误';
          break;
        case 503:
          error.msg = '服务不可用';
          break;
        case 504:
          error.msg = '网络超时';
          break;
        case 505:
          error.msg = 'http版本不支持该请求';
          break;
        default:
          error.msg = `未知错误${error.response.status}`;
      }
    } else {
      error.message = '连接到服务器失败';
    }
    return Promise.reject(error);
  }
);

/*网络请求部分*/

/*
 *  get请求
 *  url:请求地址
 *  params:参数
 * */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: 'get',
      params: params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function post(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: 'post',
      data: params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  文件上传
 *  url:请求地址
 *  params:参数
 * */
export function fileUpload(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: 'post',
      data: params,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// 导出文件下载
export function downFile(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      // 用axios发送post请求
      method: 'post',
      url: url, // 请求地址
      data: params,
      responseType: 'blob', // 表明返回服务器返回的数据类型
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default {
  get,
  post,
  fileUpload,
  downFile,
};
