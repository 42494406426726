
import { initDynamicRoutes } from "@/router";
import { onMounted } from "vue";
export default {
  setup() {
    onMounted(() => {
      if (window.sessionStorage.getItem("menuList")) {
        initDynamicRoutes();
      }
    });
  },
};
