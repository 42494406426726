import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const Login = () => import('@/views/login/Login.vue')
const NoPerms = () => import("@/views/NoPerms/noPerms.vue");
const Welcome = () => import("@/views/backStage/Welcome/welcome.vue");
const BackStage = () => import("@/views/backStage/BackStage.vue");
const Dispatch = () => import("@/views/dispatch/Dispatch.vue")
const InfoStage = () => import("@/views/infoStage/InfoStage.vue");
// 事件管理
const Event = () => import("@/views/backStage/event/Event.vue")
const EventDetails = () => import("@/views/backStage/event/EventDetails.vue")
// 设备管理
const Device = () => import("@/views/backStage/device/Device.vue")
const DeviceDetails = () => import("@/views/backStage/device/DeviceDetails.vue")
const DeviceEdit = () => import("@/views/backStage/device/DeviceEdit.vue")
const DeviceNew = () => import("@/views/backStage/device/DeviceNew.vue")
// 志愿者管理
const Volunteer = () => import("@/views/backStage/volunteer/Volunteer.vue")
const VolunteerDetails = () => import("@/views/backStage/volunteer/VolunteerDetails.vue")
const VolunteerEdit = () => import("@/views/backStage/volunteer/VolunteerEdit.vue")
// 专家管理
const Expert = () => import("@/views/backStage/expert/Expert.vue")
const ExpertDetails = () => import("@/views/backStage/expert/ExpertDetails.vue")
const ExpertEdit = () => import("@/views/backStage/expert/ExpertEdit.vue")
// 用户管理
const User = () => import("@/views/backStage/manage/user/User.vue")
const UserDetails = () => import("@/views/backStage/manage/user/UserDetails.vue")
const UserEdit = () => import("@/views/backStage/manage/user/UserEdit.vue")
// 角色、权限管理
const Role = () => import("@/views/backStage/manage/role/Role.vue")
const Permission = () => import("@/views/backStage/manage/permission/Permission.vue")
// 扫码呼救
const QrCode = () => import('@/views/backStage/qrCode/QrCode.vue')

const dispatchRule = {
  path: "/dispatch",
  name: "Dispatch",
  component: Dispatch
};
const infoStageRule = {
  path: "/infostage",
  name: "InfoStage",
  component: InfoStage
};
const eventRule = {
  path: "event",
  name: "Event",
  component: Event
};
const eventDetailsRule = {
  path: "eventDetails",
  name: "EventDetails",
  component: EventDetails
};
const deviceRule = {
  path: "device",
  name: "Device",
  component: Device
};
const deviceNewRule = {
  path: "deviceNew",
  name: "DeviceNew",
  component: DeviceNew
};
const deviceDetailsRule = {
  path: "deviceDetails",
  name: "DeviceDetails",
  component: DeviceDetails
};
const deviceEditRule = {
  path: "deviceEdit",
  name: "DeviceEdit",
  component: DeviceEdit
};
const volunteerRule = {
  path: "volunteer",
  name: "Volunteer",
  component: Volunteer
};
const volunteerDetailsRule = {
  path: "volunteerDetails",
  name: "VolunteerDetails",
  component: VolunteerDetails
};
const volunteerEditRule = {
  path: "volunteerEdit",
  name: "VolunteerEdit",
  component: VolunteerEdit
};
const expertRule = {
  path: "expert",
  name: "Expert",
  component: Expert
};
const expertDetailsRule = {
  path: "expertDetails",
  name: "ExpertDetails",
  component: ExpertDetails
};
const expertEditRule = {
  path: "expertEdit",
  name: "ExpertEdit",
  component: ExpertEdit
};
const userRule = {
  path: "user",
  name: "User",
  component: User
};
const userDetailsRule = {
  path: "userDetails",
  name: "UserDetails",
  component: UserDetails
};
const userEditRule = {
  path: "userEdit",
  name: "UserEdit",
  component: UserEdit
};
const roleRule = {
  path: "role",
  name: "Role",
  component: Role
};
const permRule = {
  path: "perm",
  name: "Permission",
  component: Permission
};
const qrCodeRule = {
  path: 'qrCode',
  name: 'QrCode',
  component: QrCode
}

const ruleMapping = {
  Dispatch: dispatchRule,
  // FirstResponder: firstResponderRule,
  InfoStage: infoStageRule,
  Event: eventRule,
  "Event:details": eventDetailsRule,
  Device: deviceRule,
  "Device:new": deviceNewRule,
  "Device:details": deviceDetailsRule,
  "Device:edit": deviceEditRule,
  Volunteer: volunteerRule,
  "Volunteer:details": volunteerDetailsRule,
  "Volunteer:edit": volunteerEditRule,
  Expert: expertRule,
  "Expert:details": expertDetailsRule,
  "Expert:edit": expertEditRule,
  User: userRule,
  "User:details": userDetailsRule,
  "User:edit": userEditRule,
  Role: roleRule,
  Permission: permRule,
  "Qrcode:qrCode": qrCodeRule,
};

const noPerms = {
  path: "/*",
  name: "NoPerms",
  component: NoPerms
};


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: "/backstage",
    name: "BackStage",
    component: BackStage,
    children: [
      {
        path: "welcome",
        name: "Welcome",
        component: Welcome
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
let flag = 0

router.beforeEach((to, from, next) => {

  const tokenStr = window.sessionStorage.getItem("token");
  if (tokenStr) {
    if (flag === 0 && to.matched.length == 0) {
      flag++
      router.push(to.path);
    } else if (flag !== 0 && to.matched.length == 0) {
      next('/login')
    } else {
      next()
    }
  } else {
    if (
      to.path === "/login" ||
      to.path === "/app7" ||
      to.path === "/sms" ||
      to.path === "/captcha" ||
      to.path === "/captchaApplet" ||
      to.path === "/qrCodeForHelp"
    ) {
      next();
    } else {
      next("/login");
    }
  }
});

export function initDynamicRoutes() {
  const menuList = JSON.parse(window.sessionStorage.getItem("menuList") as any);
  // const currentRoutes = router.options.routes;
  // 循环一级菜单
  menuList.forEach((el: any) => {
    const temp = ruleMapping[el.permission];
    // el.id === 1 || el.id === 2 ? currentRoutes.push(temp) : currentRoutes[6].children.push(temp)
    // 添加调度平台、信息平台路由
    el.id === 7
      ? ""
      : el.id === 1 || el.id === 2
        ? router.addRoute(temp)
        : router.addRoute("BackStage", temp);
    // 循环二级菜单
    if (el.children.length > 0) {
      // 添加后台管理左侧导航栏路由
      el.children.forEach((item: any) => {
        const temp = ruleMapping[item.permission];
        router.addRoute("BackStage", temp);
        // 添加三级目录路由：详情、编辑
        item.children.forEach((element: any) => {
          const temp = ruleMapping[element.permission];
          router.addRoute("BackStage", temp);
        });
      });
    }
  });
  // router.addRoutes(currentRoutes)
  router.addRoute(noPerms);

}

export default router
